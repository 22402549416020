import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'
import { Taxonomy } from '../models/Taxonomy'
import { blogPagePathBuilder } from '../templates/BlogHomePage.context'

interface BlogLinkProps {
  type: 'category' | 'tag'
}

const BlogLink = styled(Link)<BlogLinkProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.primary};

  ${({ type }) =>
    type === 'tag' &&
    css`
      &:before {
        content: '#';
      }
    `}
`

const List = styled.ul`
  background: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
  padding: 15px 13px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 24px 25px;
  }
`

const Item = styled.li``

interface BlogLinksProps {
  items: Taxonomy[]
  type: 'category' | 'tag'
  className?: string
}

const BlogLinks: React.FC<BlogLinksProps> = ({ items, type, className }) => (
  <List className={className}>
    {items.map((item, index) => (
      <Item key={index}>
        <BlogLink
          type={type}
          to={blogPagePathBuilder(
            0,
            type === 'category' ? item.slug : undefined,
            type === 'tag' ? item.slug : undefined
          )}
        >
          {item.name}
        </BlogLink>
      </Item>
    ))}
  </List>
)

export default BlogLinks
