import { useStaticQuery, graphql } from 'gatsby'
import { BlogTagsQuery } from '../_generated/codegen/graphqlTypes'
import notEmpty from '../utils/notEmpty'
import { Unpacked } from '../utils/Unpacked'

export type ITag = NonNullable<
  Unpacked<NonNullable<BlogTagsQuery['strapi']['tags']>>
>

const useTagsQuery = (): ITag[] => {
  const { strapi }: BlogTagsQuery = useStaticQuery(
    graphql`
      query BlogTags {
        strapi {
          tags: blogTags(sort: "name:asc") {
            id
            name
            slug
            posts {
              id
            }
          }
        }
      }
    `
  )
  const items = strapi.tags?.filter(notEmpty) ?? []
  return items.filter((x) => (x.posts?.length ?? 0) > 0)
}

export default useTagsQuery
