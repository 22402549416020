import React, { useMemo } from 'react'
import 'swiper/swiper-bundle.min.css'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwipperPagination from './SwipperPagination'
import styled from 'styled-components'
import useSwiperRef from '../hooks/useSwiperRef'
import { Link } from 'gatsby'
import { postPagePathBuilder } from '../templates/PostPage.context'
import { PostItem } from '../models/PostItem'
import notEmpty from '../utils/notEmpty'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

SwiperCore.use([Pagination])

const SwiperWrapper = styled(Swiper)`
  padding: 10px 2px;

  & .swiper-slide {
    max-width: 102px;
    height: unset;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 175px;
    }
  }
`

const SlideWrapper = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
`

const ImageLink = styled(Link)`
  display: inline-flex;
  width: 100%;
  height: 102px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 157px;
  }

  & > div {
    width: 100%;
    height: 100%;
  }
`

interface ImageProps {
  href: string
  image: IGatsbyImageData
  alt: string
}

const Image: React.FC<ImageProps> = ({ href, image, alt }) => (
  <ImageLink to={href}>
    <GatsbyImage image={image} alt={alt} />
  </ImageLink>
)

const Content = styled.div`
  padding: 3px 3px 6px 3px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 6px 6px 12px 6px;
  }
`

const Title = styled(Link)`
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 20px;
    line-height: 24px;
  }
`

const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`

const TagLink = styled(Link)`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.primary};

  &:before {
    content: '#';
  }
`

const Header = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.body};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 15px;
  }
`

const SwipperPaginationWrapper = styled(SwipperPagination)`
  margin-top: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 20px;
  }
`

interface SlideProps {
  item: PostItem
}

const Slide: React.FC<SlideProps> = ({ item }) => {
  const link = useMemo(
    () => postPagePathBuilder(item.category?.slug ?? '', item.slug),
    [item.category, item.slug]
  )

  const image = useMemo(() => {
    const imageData = item.cover?.imageFile?.childImageSharp?.gatsbyImageData
    if (imageData) {
      return getImage(imageData)
    }
    return undefined
  }, [])

  return (
    <SlideWrapper>
      {image && (
        <Image href={link} image={image} alt={item.cover?.name ?? ''} />
      )}
      <Content>
        <Title to={link}>{item.title}</Title>
        {item.tags && (
          <Tags>
            {item.tags.filter(notEmpty).map((tag, index) => (
              <li key={index}>
                <TagLink to={tag.slug}>{tag.name}</TagLink>
              </li>
            ))}
          </Tags>
        )}
      </Content>
    </SlideWrapper>
  )
}

interface BlogMorePostsProps {
  title: string
  items: PostItem[]
}

const BlogMorePosts: React.FC<BlogMorePostsProps> = ({ title, items }) => {
  const [paginationEl, paginationRef] = useSwiperRef()
  SwiperCore.use([Autoplay])

  if (items.length === 0) {
    return <></>
  }

  return (
    <div>
      <Header>{title}</Header>
      <div>
        <SwiperWrapper
          spaceBetween={5}
          slidesPerView="auto"
          centeredSlides={false}
          loop={true}
          loopedSlides={items.length}
          pagination={{ clickable: true, el: paginationEl }}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <Slide item={item} />
            </SwiperSlide>
          ))}
        </SwiperWrapper>
        <SwipperPaginationWrapper ref={paginationRef} />
      </div>
    </div>
  )
}

export default BlogMorePosts
