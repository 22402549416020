import React, { useCallback } from 'react'
import styled from 'styled-components'
import { PostItem } from '../models/PostItem'
import { blogPagePathBuilder } from '../templates/BlogHomePage.context'
import BlogPostCard from './BlogPostCard'
import Pagination from './Pagination'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const List = styled.ul`
  display: grid;
  gap: 18px;
  width: 100%;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(149px, 151px));
  justify-content: flex-start;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: repeat(auto-fit, minmax(216px, 219px));
    column-gap: 7px;
    row-gap: 17px;
  }
`

const PaginationWrapper = styled(Pagination)`
  margin-top: 20px;
`

interface BlogListProps {
  items: PostItem[]
  category?: string
  tag?: string
  numPages: number
  currentPage: number
}

const BlogList: React.FC<BlogListProps> = ({
  items,
  numPages,
  currentPage,
  category,
  tag,
}) => {
  const pathBuilder = useCallback(
    (i: number) => blogPagePathBuilder(i, category, tag),
    []
  )

  return (
    <Container>
      <List>
        {items.map((item, index) => (
          <BlogPostCard short key={index} item={item} />
        ))}
      </List>

      <PaginationWrapper
        numPages={numPages}
        currentPage={currentPage}
        pathBuilder={pathBuilder}
      />
    </Container>
  )
}

export default BlogList
