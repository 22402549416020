import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'featured   featured'
    'categories tags';
  row-gap: 17px;
  column-gap: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr minmax(214px, 214px);
    grid-template-areas:
      'featured categories'
      'featured tags'
      'featured 1';
    row-gap: 12px;
    column-gap: 59px;
  }

  & > *[class^='BlogPostCard__Container']  {
    grid-area: featured;
  }

  & > *[class^='BlogLinks__List'] {
    .BlogLinks_Categories  {
      grid-area: categories;
    }

    .BlogLinks_Tags {
      grid-area: tags;
    }
  }
`

interface Props {
  children: React.ReactNode
}
const BlogHeader: React.FC<Props> = ({ children }) => (
  <Container>{children}</Container>
)

export default BlogHeader
