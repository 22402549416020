import styled from 'styled-components'

const SwipperPagination = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  & > span {
    &.swiper-pagination-bullet-active {
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }
`

export default SwipperPagination
